import React, {useState} from 'react'
import {storage} from "../components/firebase";
import { Link } from "gatsby";
import NavBar from "../components/navbar";
import Helmet from 'react-helmet'
import "../components/Layout/layout.css";

import GalleryIcon from "../assets/gallery.svg";
import LeaderIcon from "../assets/leaderboard.svg";
import MessageIcon from "../assets/messageboard.svg";
import SubmitIcon from "../assets/submitscore.svg";


export default function HomePage() {
  

  return (
    <>
    <Helmet>
        <body class='body_home' />
      </Helmet>
      <div className="home_intro_copy">
      <div className="home_logo" >
          
                <img className="img_responsive"
                    alt="chevron"
                    src="/golf4Change.png" />
            
        </div>
        <p>Thank you for participating in the Golf For Change 2021 Tournament presented by Salesforce! <br></br>If you have any questions, please reach out to
<a href="mailto:gfcinfo@golfforchange.com"> gfcinfo@golfforchange.com</a>.

 </p>
        </div>
    <NavBar></NavBar>

    <div className="home_link_container">
      <div className="home_link_wrapper">

    <Link className="home_link" to="/leaderboard">
      <LeaderIcon />
      leaderboard</Link>
      <a className="home_link" href="https://docs.google.com/forms/d/e/1FAIpQLSc03j0oiMsdDcTGGaLOV4HBxSW_Zwgko0rV3kn3I9zlnhuT2Q/viewform"> 
      <SubmitIcon />
      submit score</a>
      <Link className="home_link" to="/gallery">
      <GalleryIcon />
      gallery</Link>
      
      <Link className="home_link" to="/message_board"> 
      <MessageIcon />
      Message Board</Link>
      <Link className="home_link" to="/featured">
      
      Featured talent & Charity </Link>
      </div>
    </div>
    
   </> 
  );
}